
import './StaticDocs.css';

function StaticDocs({ text }) {
    return (
        <div className={`static-docs`}>
          <p className='static-docs__text'>{text}</p>
        </div>
    );
}

export default StaticDocs