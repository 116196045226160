import Input from '../../Input/Input';
import validator from "validator";

import './PersonalInfo.css';
import { CheckedIcon } from '../../../assets/icons/icons';
import { Link } from 'react-router-dom';
import { useContext } from 'react';
import { ConfigContext } from '../../../assets/contexts/configContext';
import { DELIVERY_TYPE_VALUES, OWN_DELIVERY_TYPE } from '../../../assets/utils/constants';
import AddressInput from './AddressInput/AddressInput';
import { formatNewAddress } from '../../../assets/utils/utils';


function PersonalInfo({ personal, setPersonal, personalValidity, setPersonalValidity, delivery, setDelivery, total }) {
    const config = useContext(ConfigContext)
    const { initialConstants } = config
    const { CONSTANTS, DELIVERIES } = initialConstants
    const { SHOW_DELIVERY = true } = CONSTANTS

    function handleChange(e) {
        const input = e.target;
        const name = input.name;
        let value = input.value;

        switch (name) {
            case "phone":
                let inputValue = e.target.value.replace(/\D/g, '')
                let formattedInputValue = '';
                if (!inputValue) {
                    setPersonal(prevValue => ({
                        ...prevValue,
                        [name]: '',
                    }))
                    setPersonalValidity((prevValue) => ({
                        ...prevValue,
                        [name]: {
                            errorMessage: 'Можно вводить только цифры',
                            validState: false
                        }
                    }))
                }
                else {
                    if (['7', '8', '9'].indexOf(inputValue[0]) > -1) {
                        setPersonalValidity((prevValue) => ({
                            ...prevValue,
                            [name]: {
                                errorMessage: '',
                                validState: false
                            }
                        }))
                        if (inputValue[0] === '9') inputValue = '7' + inputValue;

                        let firstSimbols = (inputValue[0] === '8') ? '8' : '+7';
                        formattedInputValue = firstSimbols + ' '

                        if (inputValue.length > 1) {
                            formattedInputValue += '(' + inputValue.substring(1, 4)
                        }
                        if (inputValue.length >= 5) {
                            formattedInputValue += ') ' + inputValue.substring(4, 7)
                        }
                        if (inputValue.length >= 8) {
                            formattedInputValue += '-' + inputValue.substring(7, 9)
                        }
                        if (inputValue.length >= 10) {
                            formattedInputValue += '-' + inputValue.substring(9, 11)
                        }
                        if (inputValue.length >= 11) {
                            setPersonalValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: '',
                                    validState: true
                                }
                            }));
                        } else {
                            setPersonalValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: '',
                                    validState: false
                                }
                            }));
                        }
                    } else {
                        formattedInputValue = '+' + inputValue.substring(0, 16)
                        if (inputValue.length >= 11) {
                            setPersonalValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: 'Только номера РФ',
                                    validState: false
                                }
                            }));
                        } else {
                            setPersonalValidity((prevValue) => ({
                                ...prevValue,
                                [name]: {
                                    errorMessage: 'Только номера РФ',
                                    validState: false
                                }
                            }));
                        }
                    }


                    setPersonal(prevValue => ({
                        ...prevValue,
                        [name]: formattedInputValue,
                    }))
                }

                break;

            case "email":
                if (!value) {
                    setPersonalValidity((prevValue) => ({
                        ...prevValue,
                        [name]: {
                            errorMessage: "",
                            validState: false,
                        },
                    }));
                }
                if (value.length >= 2) {
                    if (validator.isEmail(value)) {
                        setPersonalValidity((prevValue) => ({
                            ...prevValue,
                            [name]: {
                                errorMessage: "",
                                validState: true,
                            },
                        }));
                    } else {
                        setPersonalValidity((prevValue) => ({
                            ...prevValue,
                            [name]: {
                                errorMessage: !e.target.validity.valid
                                    ? e.target.validationMessage
                                    : "Invalid email",
                                validState: false,
                            },
                        }));
                    }
                }
                setPersonal((prevValue) => ({
                    ...prevValue,
                    [name]: value,
                }));
                break;

            default:
                setPersonal((prevValue) => ({
                    ...prevValue,
                    [name]: value,
                }));
                break;
        }
    }

    function handlePhoneDelite(e) {
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length === 1) {
            setPersonal(prevValue => ({
                ...prevValue,
                phone: '',
            }))
        }
        if (e.keyCode === 8 && e.target.value.replace(/\D/g, '').length < 11) {
            setPersonalValidity(prevValue => ({
                ...prevValue,
                phone: {
                    errorMessage: '',
                    validState: false
                }
            }));
        }

    }

    function handleSelectDeliveryType({ item }) {
        setDelivery(prevValue => ({
            ...prevValue,
            type: item,
            hint_selected: '',
            address: '',
            entrance: '',
            entry_code: '',
            floor: '',
            apartment: '',
            comment: '',
        }))
        console.log(item)
    }

    function toggleCheckbox() {
        setPersonal(prevValue => ({
            ...prevValue,
            checkbox: !prevValue.checkbox,
        }))
    }

    function handleDeliveryChange(e) {
        const input = e.target;
        const name = input.name;
        let value = input.value;
        setDelivery((prevValue) => ({
            ...prevValue,
            [name]: value,
        }));
    }



    return (
        <form className='personal-info' onSubmit={(evt) => { evt.preventDefault() }}>
            <div className='personal-info__inputs-container'>
                <h3 className='personal-info__inputs-title'>Информация о получателе</h3>
                <div className='personal-info__two-inputs'>
                    <div className="personal-info__input">
                        <Input
                            label={"Имя"}
                            value={personal.first_name}
                            handleChange={handleChange}
                            name={"first_name"}
                        // error={formValid.email}
                        ></Input>
                    </div>
                    <div className="personal-info__input">
                        <Input
                            label={"Фамилия"}
                            value={personal.last_name}
                            handleChange={handleChange}
                            name={"last_name"}
                        // error={formValid.email}
                        ></Input>
                    </div>
                </div>
                <div className='personal-info__two-inputs'>
                    <div className="personal-info__input">
                        <Input
                            label={"Номер телефона"}
                            value={personal.phone}
                            handleChange={handleChange}
                            name={"phone"}
                            inputMode='tel'
                            error={personalValidity.phone}
                            onKeyDown={handlePhoneDelite}
                        ></Input>
                    </div>
                    <div className="personal-info__input">
                        <Input
                            label={"Эл. почта"}
                            value={personal.email}
                            handleChange={handleChange}
                            name={"email"}
                            inputMode='email'
                        // error={personalValidity.email}
                        ></Input>
                    </div>
                </div>
            </div>
            {DELIVERIES && DELIVERIES.length > 1 ?
                <div className='personal-info__inputs-container'>
                    <h3 className='personal-info__inputs-title'>Способ получения</h3>
                    <div className='personal-info__select-input-box' style={{
                        gridTemplateColumns: `repeat(${DELIVERIES.length}, minMax(0, 1fr))`,
                    }}>
                        {DELIVERIES.map((item, i) => (
                            <button className={`personal-info__select-input ${delivery?.type?.delivery_type === item.delivery_type ? 'personal-info__select-input_selected' : ''}`} key={`personal-info__select-input_delivery-type_${i}`} type='button' onClick={() => {
                                handleSelectDeliveryType({ item })
                            }}>
                                {DELIVERY_TYPE_VALUES[item.delivery_type]}
                            </button>
                        ))}

                    </div>
                </div>
                :
                null}

            <div className='personal-info__inputs-container'>
                {delivery?.type?.delivery_type === OWN_DELIVERY_TYPE ?
                    <>
                        {DELIVERIES && DELIVERIES.length === 1 && delivery.type.delivery_type === OWN_DELIVERY_TYPE ?
                            <h3 className='personal-info__inputs-title'>Адрес доставки</h3>
                            : null}
                        <div className="personal-info__address-input">
                            {/* <div className="personal-info__input">
                                <Input
                                    label={"Адрес"}
                                    value={delivery.comment}
                                    handleChange={handleDeliveryChange}
                                    name={"comment"}
                                // error={formValid.email}
                                ></Input>
                            </div> */}
                            <AddressInput
                                form={delivery}
                                setForm={setDelivery}
                                error={total.NOT_DELIVERABLE}
                                hint={total.DELIVERY_TIME}
                            />
                        </div>
                        <div className='personal-info__two-inputs'>
                            <div className="personal-info__input">
                                <Input
                                    label={"Квартира"}
                                    value={delivery.apartment}
                                    handleChange={handleDeliveryChange}
                                    name={"apartment"}
                                // error={formValid.email}
                                ></Input>
                            </div>
                            <div className="personal-info__input">
                                <Input
                                    label={"Домофон"}
                                    value={delivery.entry_code}
                                    handleChange={handleDeliveryChange}
                                    name={"entry_code"}
                                // error={formValid.email}
                                ></Input>
                            </div>
                        </div>
                        <div className='personal-info__two-inputs'>
                            <div className="personal-info__input">
                                <Input
                                    label={"Подъезд"}
                                    value={delivery.entrance}
                                    handleChange={handleDeliveryChange}
                                    name={"entrance"}
                                // error={formValid.email}
                                ></Input>
                            </div>
                            <div className="personal-info__input">
                                <Input
                                    label={"Этаж"}
                                    value={delivery.floor}
                                    handleChange={handleDeliveryChange}
                                    name={"floor"}
                                // error={formValid.email}
                                ></Input>
                            </div>

                        </div>
                        <div className="personal-info__input">
                            <Input
                                label={"Комментарий для курьера"}
                                value={delivery.comment}
                                handleChange={handleDeliveryChange}
                                name={"comment"}
                            // error={formValid.email}
                            ></Input>
                        </div>
                    </>

                    :
                    SHOW_DELIVERY && delivery?.type?.data ?
                        <>
                            <p className='personal-info__no-delivery-title'>Самовывоз по адресу: <b>{formatNewAddress(delivery.type.data)}</b></p>
                        </>
                        : null

                }


                <button className={`personal-info__checkbox ${personal.checkbox ? 'personal-info__checkbox_selected' : ''}`} onClick={toggleCheckbox} type='button'>
                    <div className='personal-info__checkbox-box'>
                        <CheckedIcon mainClassName={'personal-info__checkbox-box-icon'} strokeClassName={'personal-info__checkbox-box-icon-stroke'} />
                    </div>
                    <p className='personal-info__checkbox-text'>Оформляя заказ вы соглашаетесь с условиями <Link to={'/docs/public_offer'} className='personal-info__checkbox-text-link'>оферты</Link> и даете <Link to={'/docs/user_agreement'} className='personal-info__checkbox-text-link'>согласие на обработку персональных данных</Link></p>
                </button>
            </div>

        </form>
    );
}

export default PersonalInfo