
import "./SubmitActionPopup.css";
import React, { useEffect } from "react";

function SubmitActionPopup({
  isOpen,
  hendleClose,
  hendleSubmit,
  text,
  submitText,
  cencelText,
  isSubmitPreloader,
  submitError,
}) {




  return (
    <div
      className={`submit-act-popup ${isOpen ? "submit-act-popup_active" : ""}`}
    >
      <div
        className={`submit-act-popup__container ${isOpen ? "submit-act-popup__container_active" : ""
          }`}
      >

        <p className="submit-act-popup__title">Для людей старше 18&nbsp;лет</p>
        <p className="submit-act-popup__text">Использовать сайт разрешается лицам, достигшим 18&nbsp;лет. <br />
Вам больше 18&nbsp;лет?</p>
        <div className="submit-act-popup__btns">
          <button
            className="submit-act-popup__btn submit-act-popup__btn_agree"
            type="button"
            onClick={hendleSubmit}
          >
           Да, мне больше 18&nbsp;лет
          </button>
          <button
            className="submit-act-popup__btn submit-act-popup__btn_disagree"
            type="button"
            onClick={hendleClose}
          >
            Нет, мне меньше&nbsp;18
          </button>
        </div>
      </div>
      <div
        className={`submit-act-popup__background ${isOpen ? "submit-act-popup__background_active" : ""
          }`}
        onClick={hendleClose}
      ></div>
    </div>
  );
}

export default SubmitActionPopup;
