export const USER_AGREEMENT_TEXT = `
Пользовательское соглашение

Для целей применения и толкования настоящей публичной оферты (далее – «Оферта») используются определенные ниже основные термины (если в Оферте прямо не указано иное). В тексте Оферты эти термины могут быть указаны с большой или маленькой буквы в единственном или множественном числе, а также в виде сокращений.

1.	Термины и определения

1.1.	Продавец – ${window.initialConstants?.SHOP_NAME}, реквизиты которого приведены в разделе 12 настоящей Оферты, собственник Товара, размещенного на Сайте.
1.2.	Покупатель – дееспособное физическое лицо, принимающее условия настоящего Договора и размещающее Заказы на Сайте.
1.3.	Сайт – Интернет-сайт, принадлежащий Продавцу, имеющий адрес в сети Интернет ${window.location.protocol}//${window.location.host}${window.initialConstants.ROOT_PATH ? `/${window.initialConstants.ROOT_PATH}` : ''}. На Сайте представлены Товары (Услуги), предлагаемые Продавцом своим Покупателям для оформления Заказов, а также условия оплаты и доставки этих Заказов Покупателям.
1.4.	Аккаунт - созданная с помощью мобильного устройства или компьютера учетная запись Покупателя, которая представляет собой совокупность данных о Покупателе, данные его персонального личного кабинета, через который можно управлять настройками страницы, менять информацию на странице, регулировать порядок получения уведомлений и совершать иные действия, предусмотренные функционалом Сайта.
1.5.	Товар - предмет Договора, представляющий собой вещь, перечень наименований и ассортимент, которых представлен на Сайте. Под товаром в настоящей Оферте может также пониматься услуга или работа в случае, если таковая предлагается к оказанию Продавцом Покупателю на Сайте.
1.6.	Заказ — должным образом оформленный запрос Покупателя на приобретение и доставку по указанному адресу из перечня Товаров, выбранных на Сайте, либо оказание Услуг в порядке и на условиях, указанных на Сайте и в подтвержденном Заказе. Заказ может быть оформлен как в целях заключения договора розничной купли-продажи (договора оказания услуг) с Продавцом, так и для осуществления обмена или замены Товаров по ранее заключенному договору в случаях, предусмотренных законодательством РФ.
1.7.	Получатель – Покупатель или уполномоченное им на получение Заказа третье лицо.
1.8.	Отмена Заказа или Товара – техническое действие Продавца, не подразумевающее отказ Продавца от исполнения договора, которое осуществляется на Сайте и констатирует факт того, что некоторые Товары в составе Заказа (отмена Товара) либо все Товары в составе Заказа (отмена Заказа) не переданы Покупателю по текущему Заказу, либо часть или все Услуги не были оказаны Покупателю. Продавец обязан уведомить зарегистрированных Покупателей обо всех случаях отмены, в том числе, в случаях отказа от Товаров и/или Услуг по инициативе Покупателя, путем направления электронного сообщения об отмене по электронному адресу, указанному Покупателем при регистрации.
1.9.	Служба доставки - третье лицо, оказывающее по договору с Продавцом услуги по доставке Заказов Покупателю или Получателю.
1.10.	Верификация - процедура, позволяющая установить реальность и принадлежность определенного номера телефона конкретному физическому лицу, в результате которой создается Аккаунт, привязанный к верифицированному номеру телефона.
1.11.	В настоящей Оферте могут быть использованы термины, не определенные в данном разделе. В этом случае толкование такого термина производится в соответствии с текстом Оферты. В случае отсутствия однозначного толкования термина в тексте следует руководствоваться толкованием термина, определенным в первую очередь на Сайте, а затем сложившимся (общеупотребительным) языком в сети Интернет и других источниках.

2.	Общие положения

2.1.	Настоящая Оферта является официальным предложением Продавца в адрес любого дееспособного физического лица заключить с Продавцом договор купли-продажи Товара (договор оказания Услуг) на условиях, определенных в настоящей Оферте.
2.2.	Отношения между Покупателем и Продавцом регулируются положениями Гражданского кодекса Российской Федерации (далее – ГК РФ), Законом РФ от 07.02.1992 N 2300-1 "О защите прав потребителей" (далее – Закон о защите прав потребителей), Правилами продажи товаров дистанционным способом (Постановление Правительства РФ от 27.09.2007 N 612), а также нормативно-правовыми актами РФ, регулирующими оборот отдельных видов товаров (если применимо)
2.3.	Публичная оферта признается акцептованной Покупателем с момента прохождения процедуры Верификации и оформления Покупателем Заказа на Сайте. В случае несогласия с условиями Публичной оферты Покупатель обязан немедленно прекратить использование и покинуть Сайт.
2.4.	Покупатель соглашается с тем, что Продавец вправе поручить исполнение Договора третьему лицу, оставаясь ответственным за его исполнение.
2.5.	Продавец оставляет за собой право вносить изменения в настоящую Оферту посредством публикации новой редакции Оферты на Сайте, в связи с чем Покупатель обязуется самостоятельно регулярно отслеживать изменения в Оферте, размещенной на Сайте
2.6.	Покупатель соглашается с полным текстом Оферты со всеми приложениями и дополнительными разделами на Сайте, создавая Аккаунт, оформляя Заказы, либо путем прохождения Верификации по номеру телефона, как это описано в разделе 4 настоящей Оферты.
2.7.	Покупатель соглашается с условиями продажи выбранных им Товаров (условия индивидуального договора купли-продажи) либо с условиями оказания Услуг нажатием кнопки «Подтвердить заказ» на последнем этапе оформления Заказа на Сайте. Совершение указанных действий является фактом, подтверждающим заключение договора между Покупателем и Продавцом
2.8.	В случае проведения стимулирующих мероприятий - акций, в условиях акций, размещаемых на Сайте, могут быть установлены специальные положения, регулирующие порядок оформления заказа и возврата товара (отказа от Услуг). При этом условия акций являются неотъемлемой частью настоящей Оферты, и подлежат применению для лиц, участвующих в акциях. Оформление акционного Заказа и/или выполнение иных условий участия в акции, означает согласие Покупателя с условиями соответствующей акции.

3.	Гарантии покупателя

3.1.	Принимая настоящую Оферту, Покупатель безусловно и безоговорочно:
3.1.1.	Гарантирует, что денежные средства, которые используются Покупателем не добыты преступным путем и имеют легальный источник происхождения, в противном случае Покупатель обязан воздержаться от совершения любых действий на Сайте.
3.1.2.	Гарантирует, что он является законными владельцем денежных средств и имеет право использовать все денежные суммы, и что совершаемые им покупки не нарушают права любых третьих лиц или действующее законодательство.
3.1.3.	Гарантирует, что он не будет совершать действий с целью получить несанкционированный доступ к Сайту, и любая попытка сделать это или содействие другим Покупателям или третьим лицам в этом (включая распространение инструкций, программного обеспечения и инструментов для этой цели) приведет к расторжению настоящего Оферты с Покупателем. Также, Продавец в этом случае, оставляет за собой право принять любые иные действия против Покупателя, включая, но не ограничиваясь, направлением информации в правоохранительные или иные компетентные органы.
3.1.4.	Признает и соглашается, что с целью обеспечения безопасности Аккаунтов Покупателей и противодействия легализации (отмыванию) денежных средств, в рамках реализации внутренней политики безопасности, Продавец по умолчанию применяет средства внутреннего контроля и программы для его осуществления, собирает и хранит любую информацию о существенных фактах относительно Покупателей и их покупок, включая личную информацию о Покупателях, предоставленную ими при регистрации Аккаунта, или запрошенную Продавцом при осуществлении покупок, а также косвенную информацию, в том числе IP-адреса, информацию об используемой операционной системе, конфигурации программного обеспечения, и другую информацию, собранную с применением технологии «cookies» («куки») для создания статистической отчетности.
3.1.5.	Признает и соглашается с тем, что Продавец может запросить у Покупателя любую дополнительную идентификационную информацию и дополнительные документы в любое время по просьбе любого компетентного органа или при применении любого действующего законодательства или регулирования любой страны, включая законодательство о противодействии отмыванию (легализации) денежных средств, полученных преступным путем, или о противодействии финансированию терроризма. В указанном случае, если Покупатель не предоставит, предоставит в неполном объеме или недостоверную запрашиваемую информацию и документы, Продавец имеет право в одностороннем порядке расторгнуть настоящую Оферту с Покупателем.
3.1.6.	Понимает и соглашается, что любые претензии и запросы принимаются и обслуживаются только через службу поддержки, адрес электронной почты которой указан на Сайте. Покупатель понимает и согласен, что ни на форумах, ни в социальных сетях, ни где-либо еще, кроме официального email адреса, указанного в разделе «Контактные данные Продавца» на Сайте, его вопросы и претензии не будут рассмотрены надлежащим образом. Продавец не несет ответственности в случае, если Покупатель потерпел ущерб вследствие обращения по иным контактным данным.
3.1.7.	Понимает и соглашается с тем, что Продавец может, приостанавливать, ограничивать или прекращать доступ конкретного Покупателя или всех Покупателей сразу ко всем или к любому из разделов Сайта в любое время по любой причине или без объяснения причин, с предварительным уведомлением или без такового, не отвечая за любой вред, который может быть причинен Покупателю таким действием.
3.1.8.	Принимает и соглашается с тем, что посещение Сайта, приобретение и доставка Покупателю конкретного Товара (оказание конкретной Услуги) могут быть признаны незаконными на территории страны, резидентом которой является Покупатель.
3.1.9.	Покупатель несет ответственность за невыполнение законов своей страны при посещении Сайта и попытке приобрести Товар (Услуги), если таковые запрещены законодательством на территории страны, резидентом которой он является.
3.1.10.	Принимает и соглашается с тем, что условия доставки Товара могут иметь экспортные и лицензионные ограничения по осуществлению такой доставки.

4.	Верификация

4.1.	Для регистрации на Сайте и/или оформления Заказа Покупателю необходимо пройти следующую процедуру Верификации по номеру телефона:
	ввести номер телефона в специальное поле, после чего нажать кнопку «отправить код»;
	владельцу телефона посредством смс-сообщений и/или пуш-уведомлений и/или посредством приложений и/или мессенджеров для смартфонов и/или иным образом на, указанный Покупателем номер телефона, направляется индивидуальный код, который он вводит в поле, предназначенное для кода;
	при совпадении отправленного и введенного кода Верификация признается успешно пройденной – устанавливается принадлежность номера телефона определенному физическому лицу, чьи данные содержатся в Аккаунте и/или в информации о Покупателе на стадии оформления Заказа.
4.2.	Если у Покупателя уже есть Аккаунт на Сайте, то после успешной Верификации происходит автоматический вход в этот Аккаунт. Иначе Покупателю предлагается ввести адрес электронной почты и пароль от существующего Аккаунта, либо создать новый Аккаунт.
4.3.	В случае изменения номера телефона Покупатель может воспользоваться процедурой изменения номера телефона в Аккаунте с использованием функционала Аккаунта на Сайте.
4.4.	Если Покупатель использовал один и тот же номер телефона для разных Аккаунтов, то при первичной Верификации он вправе выбрать один Аккаунт и указать логин и пароль от этого Аккаунта, который становится основным.
4.5.	Покупатель также вправе зайти в Аккаунт путем ввода логина (адрес электронной почты) и пароля от Аккаунта, однако для оформления Заказов требуется прохождение процедуры Верификации номера телефона Покупателя, описанной в данном разделе.
4.6.	Покупатель обязуется не сообщать третьим лицам логин и пароль, номер телефона и индивидуальные коды. Продавец не несет какой-либо ответственности за ущерб, причиненный Покупателю вследствие утери указанных данных и неправомерных действий третьих лиц.
4.7.	Одноразовый код, направляемый для Верификации номера телефона, является простой электронной подписью, которой Покупатель подтверждает принадлежность указанного номера телефона Покупателю и его согласие с настоящей Офертой.
4.8.	Проходя Верификацию, Покупатель соглашается с получением сообщений сервисного характера, направляемых на адрес электронной почты, указанный при регистрации, и/или посредством смс-сообщений и/или пуш-уведомлений и/или иным образом на номер телефона, указанный Покупателем при регистрации и/или оформлении заказа, о состоянии заказа, условиях доставки, товарах в разделе «Корзина» и/или добавленных Покупателем в "Избранное", а также просьба Продавца оставить отзыв о приобретенном Товаре. Отказ Покупателя от получения указанных сообщений невозможен по техническим причинам.
4.9.	Покупатель соглашается с тем, что к его Аккаунту могут быть применены любые ограничения (в том числе блокировка), связанные с безопасностью или нарушением Покупателем настоящей Оферты, а также по причинам технического характера, в том числе по причинам, не зависящим от Продавца. Кроме того, принимая настоящую Оферту, Покупатель соглашается с тем, что ограничения на его Аккаунте могут быть применены в любое время без объяснения причин, на любой срок и без уведомления Покупателя.

5.	Оформление и сроки выполнения заказа

5.1.	Заказ может быть оформлен Покупателем самостоятельно на Сайте путем осуществления следующей последовательности действий:
	Покупатель выбирает товары (Услуги) и добавляет их в раздел «Корзина».
	Переходит в раздел «Ваша корзина» и нажимает «Оформить заказ».
	Указывает свои данные. Если Покупатель не авторизован, то проходит процедуру авторизации с помощью номера телефона, электронной почты.
	Выбирает способ доставки из способов, предложенных Продавцом, и указывает адрес доставки/пункт самовывоза.
	Выбирает способ оплаты из способов, предложенных Продавцом (если применимо).
	Соглашается с условиями Публичной оферты (предоставляет акцепт условий Публичной оферты»), посредством размещения отметки «галочки» в поле напротив ссылки на Публичную оферту
	Нажимает кнопку «Оформление заказа»
	Оплачивает заказ, если выбрана форма оплаты онлайн (если применимо).
5.2.	При оформлении Заказа Покупатель должен пройти процедуру Верификации и указать следующую информацию:
	Ф.И.О. и номер телефона Покупателя;
	Ф.И.О. и номер телефона Получателя Заказа (если он отличается от Покупателя);
	Адрес доставки/самовывоза Заказа /оказания Услуг.
5.3.	После оформления Заказа Покупателю предоставляется информация об ожидаемой дате доставки Заказа (оказания Услуг по Заказу). Эта дата означает срок, в который Служба доставки будет готова вручить Заказ Покупателю либо оказать Услуги по Заказу. Указанная дата зависит от наличия заказанных Товаров на складе, времени, необходимого на обработку Заказа и сроков доставки Заказа, выбранной при оформлении Заказа Службой доставки. Покупатель соглашается с тем, что срок доставки может быть увеличен Службой доставки в одностороннем порядке. Продавец не несет ответственности за такое изменение сроков доставки.
5.4.	Если Покупателем оформлен Заказ на Товар в количестве, превышающем имеющееся на складе у Продавца необходимого количество заказанного Товара, Продавец информирует об этом Покупателя посредством направления электронного сообщения. Сообщение направляется по электронному адресу, указанному при регистрации. Покупатель вправе согласиться принять Товар в количестве, имеющемся в наличии у Продавца, либо отменить данную позицию Товара из Заказа. В случае неполучения ответа Покупателя в течение 14 дней Продавец оставляет за собой право отменить данный Товар из Заказа.
5.5.	Продавец вправе отменить Заказы Покупателя, содержащие Товары (Услуги), от которых ранее Покупатель отказался 5 и более раз, указав причины, не связанные с наличием недостатков в этих Товарах.
5.6.	Информация о наличии Товара на складе Продавца и ожидаемый срок поставки Товара на склад Продавца либо ожидаемый срок оказания Услуг указываются на Сайте на странице Товара (Услуги). Конечные сроки получения Заказа (Услуги) Покупателем зависят от адреса и региона доставки, работы конкретной Службы доставки, и не зависят от Продавца.
5.7.	Все информационные материалы о Товарах (Услугах), представленные на Сайте, носят справочный характер и не могут в полной мере передавать достоверную информацию об Услугах, о свойствах и характеристиках Товара, включая цвета, размеры и формы. В случае возникновения у Покупателя вопросов, касающихся свойств и характеристик Товара либо Услуг, перед оформлением Заказа, Покупатель должен обратиться к Продавцу по контактным данным, указанным на Сайте.
5.8.	При оформлении некоторых Заказов Покупатель может выбрать способ формирования Заказа в одно или несколько отправлений.
5.9.	Если в соответствии с требованиями законодательства необходима проверка работоспособности Товара перед передачей его Покупателю, такая проверка осуществляется на складе Продавца до передачи Товара в Службу Доставки.
5.10.	Отмена Заказа, Услуги или Товара.
5.10.1.	Отмена Заказа, Услуги или Товара производится по инициативе Покупателя либо Продавца. Продавец отменяет Заказ в случае непоступления оплаты от Покупателя.
5.10.2.	Если исполнение заказа со стороны Продавца стало невозможным по причинам, не зависящим от воли Покупателя или Продавца (в случае закрытия региона доставки Службой доставки, изменения таможенных правил и процедур, закрытия Пункта выдачи заказов, выбранного Покупателем в качестве способа доставки, и других обстоятельств, препятствующих передаче Товара либо оказанию Услуги согласованным способом, в том числе в случае утери заказа при доставке, отсутствии Товара на складе, отсутствия Товаров на складе в виду выявленного при обработке заказа брака, пересорта или неработоспособности Товара и т.п.), Продавец отменяет заказ и уведомляет Покупателя, а Покупатель вправе согласовать новые условия доставки Товаров и/или оказания Услуг путем оформления нового Заказа, если на момент оформления заказа Товар и/или Услуга есть в продаже. Если на момент оформления заказа цена Товара и/или Услуги увеличилась, а предыдущий заказ был отменен не по причине брака, компенсация разницы в цене на день оформления нового Заказа не начисляется.
5.10.3.	Покупатель вправе отказаться от заказанного Товара и/или Услуги в любое время до его получения (доставки), за исключением случая, указанного в п. 5.10.5 настоящей Оферты.
5.10.4.	Покупатель вправе полностью или частично отказаться от Товаров, Услуг либо Заказа в момент получения (доставки), за исключением случая, указанного в п. 5.10.5 настоящей Оферты.
5.10.5.	Покупатель в любом случае не вправе отказаться от Товара надлежащего качества, имеющего индивидуально-определенные свойства, если указанный Товар может быть использован исключительно приобретающим его Покупателем.
5.10.6.	По предоплаченному Заказу стоимость доставки подлежит возврату в случае, если Заказ был отменен или не доставлен.
5.10.7.	С текущим статусом Заказа Покупатель может ознакомиться в Аккаунте.
5.10.8.	По предоплаченному Заказу при полной или частичной отмене Заказа, Услуги или Товара до момента его получения и в момент передачи Покупателю, будет оформлен возврат на карту, с которой производилась оплата, на основании соответствующего письменного запроса Покупателя.

6.	Доставка товара

6.1.	Доставку осуществляет Служба доставки, привлеченная Продавцом и выбранная Покупателем, в том числе, с привлечением третьих лиц. Способы доставки Товаров указаны на Сайте. Возможность доставки до адреса получателя определяется в момент оформления заказа. Согласованным способом доставки считается способ, выбранный Покупателем из доступных способов доставки при оформлении Заказа.
6.2.	Территория доставки некоторых видов Товаров может быть ограничена. Перечень товаров, доставка которых ограничена, содержится разделе «Доставка» на Сайте.
6.3.	При наличии у Покупателя оснований, дающих ему право на получение товаров, свободная доставка которых ограничена, Покупатель вправе обратиться в Службу поддержки для решения вопроса в индивидуальном порядке. При этом Продавец не гарантирует Покупателю возможность доставки таких товаров.
6.4.	Продавец приложит все усилия для соблюдения сроков доставки, указанных на Сайте, тем не менее, задержки в доставке возможны ввиду непредвиденных обстоятельств, произошедших не по вине Продавца. Продавец не несет ответственности за нарушение сроков доставки, вызванное обстоятельствами, не зависящими от Продавца, в том числе в результате действий (бездействия) Службы доставки.
6.5.	Риск случайной гибели или случайного повреждения Товара переходит к Покупателю с момента передачи ему Заказа, если иное не предусмотрено настоящей Офертой.
6.6.	В случае выбора Покупателем в качестве Службы доставки международных почтовых отправлений Почты России риск случайной гибели или случайного повреждения Товара лежит на Продавце в полном объеме до передачи Товара в Службу доставки. После передачи Товара в указанную Службу доставки ответственность Продавца определяется в пределах, установленных международным почтовым законодательством (Всемирной почтовой конвенцией от 12 августа 2008 года).
6.7.	В случае выбора Покупателем способа доставки «Оставить посылку у двери» риск случайной гибели или случайного повреждения Товара лежит на Покупателе в момент отправки Покупателю уведомления о доставке Товара без непосредственной передачи Товара. Продавец не несет ответственности за отсутствие Товара в месте его доставки после направления соответствующего уведомления, а также за возможные дефекты и повреждения, обнаруженные Покупателем при применении такого способа доставки.
6.8.	Стоимость доставки каждого Заказа рассчитывается исходя из его веса, региона и способа доставки, формы оплаты, и указывается на последнем этапе оформления Заказа на Сайте.
6.9.	При доставке Заказ вручается Покупателю либо лицу, указанному в качестве Получателя Заказа. При невозможности получения Заказа, оформленного за наличный расчет, указанными выше лицами, Заказ вручается лицу, готовому предоставить сведения о Заказе (номер отправления и/или ФИО Получателя), а также оплатить стоимость Заказа в полном объеме лицу, осуществляющему доставку Заказа. В таком случае Продавец вправе запросить у Покупателя письменное согласие на получение и оплату Заказа третьим лицом. 
6.10.	Во избежание случаев мошенничества, а также для выполнения взятых на себя в пункте 6.9 Оферты обязательств, при вручении предоплаченного Заказа лицо, осуществляющее доставку Заказа, вправе затребовать документ, удостоверяющий личность Получателя. Продавец не несет ответственности, если Покупатель или Получатель Заказа отказываются предоставить документ, удостоверяющий личность, и вправе отказать в передаче Заказа до момента предоставления документа, удостоверяющего личность, без уплаты каких-либо штрафных санкций.
6.11.	При покупке физическим лицом ювелирных изделий из драгоценных металлов и драгоценных камней на сумму свыше 40 000 рублей, а также при использовании персонифицированного электронного средства платежа для совершения покупки на сумму свыше 100 000 рублей в соответствии с пунктом 1.4-2 статьи 7 Федерального закона от 07.08.2001 № 115-ФЗ «О противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма» лицо, осуществляющее доставку Заказа обязано запросить документ, удостоверяющий личность Получателя и провести процедуру идентификации путем заполнения специальной формы Анкеты для передачи сведений в Росфинмониторинг, а Получатель обязан предоставить документ, удостоверяющий личность по требованию.
6.12.	При передаче Заказа Покупатель в присутствии лица, осуществляющего доставку Заказа, должен проверить внешний вид в части недостатков, которые могут быть выявлены при обычной приемке, и упаковку Заказа, количество Товаров в Заказе, внешний вид и упаковку Товаров, комплектность, ассортимент. Вскрытие индивидуальной упаковки ювелирных изделий из драгоценных металлов и драгоценных камней должны быть без повреждения целостности этикеток, ярлыков и пломб, прикрепленных к изделию, а примерка изделий осуществляется только в присутствии лица, доставившего или выдавшего Заказ. Продавец вправе отказать в рассмотрении претензий в отношении недостатков, которые могут быть выявлены в ходе обычной приемки, после приемки Товара. 
6.13.	Сроки, отведенные для получения Заказа Покупателем, ограничены и указываются при оформлении Заказа, на этапе выбора соответствующего способа доставки. Дополнительная информация представлена в соответствующем разделе на Сайте.
6.14.	Неполучение Заказа в указанные в п. 6.13 Оферты сроки считается отказом Покупателя от договора купли-продажи и является основанием для отмены Заказа Продавцом. Если неполученный Заказ был предоплачен, денежные средства возвращаются Покупателю в порядке, предусмотренном п. 8.3.2. Оферты.
6.15.	Покупатель вправе заявлять претензии в отношении недостатков, которые не могли были быть выявлены в ходе обычной приемки (скрытых недостатков), в течение 3 (трех) рабочих дней с даты приемки Товара. Продавец вправе отказать в рассмотрении претензий в отношении скрытых недостатков Товара, выявленных за пределами данного срока. 

7.	Оплата товара и/или Услуг

7.1.	Цена Товара (Услуг) указывается на Сайте. В случае неверного указания цены заказанного Покупателем Товара (Услуги), Продавец при первой возможности информирует об этом Покупателя для подтверждения Заказа по исправленной цене либо отмене Заказа. При невозможности связаться с Покупателем данный Заказ считается отмененным. Если Заказ был предварительно оплачен, Продавец возвращает Покупателю оплаченную за Заказ сумму на банковскую карту, с которой был осуществлен платеж либо осуществляет возврат денежных средств в порядке, предусмотренном п. 8.3.2. Оферты.
7.2.	Цена Товара (Услуги), указанная на Сайте, может быть изменена Продавцом в одностороннем порядке. Цена Товара (Услуги) действительна на момент нажатия кнопки "Подтвердить заказ" на последнем этапе оформления Заказа. При этом цена на заказанный Покупателем Товар (Услугу) изменению не подлежит.
7.3.	Способы оплаты Товара (Услуги) указаны на Сайте в разделе «Оплата и доставка». Согласованным способом оплаты считается способ, выбранный Покупателем из доступных способов оплаты при оформлении Заказа.
7.4.	Для оплаты товаров из Заказа на сумму более 100 000 рублей могут быть использованы все способы оплаты, кроме наличных денежных средств.
7.5.	При предоплате Товаров (Услуг) Заказ принимается в обработку только после зачисления денежных средств Покупателя на расчетный счет Продавца. При этом Товар и/или Услуга, помещенные Покупателем в раздел «Корзина», подлежит оплате в тот же день. В случае, если оплата за Товар и/или Услугу не осуществлена в указанный срок, Продавец не может гарантировать доступность Услуги и/или Товара на складе Продавца, вследствие чего могут увеличиться сроки обработки Заказа либо Заказ может быть отменен Продавцом полностью или частично.
7.6.	В соответствии с положением ЦБ РФ "Об эмиссии банковских карт и об операциях, совершаемых с использованием платежных карт" от 24.12.2004 №266-П операции по банковским картам совершаются держателем карты либо лицом, уполномоченным на основании доверенности, оформленной в соответствии с законодательством.
7.7.	При совершении оплаты Товара с помощью банковской карты в Пункте выдачи заказов Покупатель должен предъявить документ, удостоверяющий личность, за исключением случая оплаты неименной банковской картой. Продавец не несет ответственности, если Покупатель или Получатель Заказа отказываются предоставить документ, удостоверяющий личность, и вправе отказать в передаче Заказа до момента предоставления документа, удостоверяющего личность, без уплаты каких-либо штрафных санкций.
7.8.	Оплата заказа с помощью банковских карт осуществляется Покупателем самостоятельно на Сайте путем переадресации на платежную форму Банка и осуществления последовательности действий, указанных в платежной форме.
7.9.	При осуществлении оплаты с использованием банковской карты происходит переадресация с сайта Продавца на страницу, содержащую платежную Банка для указания реквизитов банковской карты Покупателя.
7.10.	Авторизация операций по банковским картам осуществляется банком. Если у банка есть основания полагать, что операция носит мошеннический характер, то банк вправе отказать в осуществлении данной операции. Продавец не несет ответственность за отказ банка от осуществления денежной операции.
7.11.	Во избежание случаев различного рода неправомерного использования банковских карт при оплате, все Заказы, оформленные на Сайте и предоплаченные банковской картой, могут быть проверены Продавцом. Продавец оставляет за собой право без объяснения причины отменить Заказ. Оплаченная стоимость Заказа возвращается на счет Покупателя по реквизитам банковской карты, указанной Покупателем при оформлении Заказа.
7.12.	Прием и обработка платежей с использованием банковских карт проводится провайдером электронных платежей. Продавец не осуществляет обработку, в том числе сбор и хранение данных банковских карт Покупателей.
7.13.	Осуществляя платеж банковской картой, а также при оплате заказа при получении, Покупатель соглашается с направлением ему кассового чека в электронной форме (ссылки на кассовый чек с возможностью скачать его в формате PDF) на электронную почту, либо на Верифицированный номер телефона в случае отсутствия информации об электронной почте в Аккаунте. Кассовый чек в печатной форме в таком случае будет предоставлен только по требованию Покупателя.
7.14.	Продавец вправе предоставлять Покупателю скидки на Товар и устанавливать программу бонусов. Виды скидок, бонусов, порядок и условия начисления указаны на Сайте в соответствующих тематических блоках раздела "Служба поддержки" и могут быть изменены Продавцом в одностороннем порядке.
7.15.	Продавец вправе устанавливать скидки в целях продвижения того либо иного способа оплаты или доставки Товара или оказания Услуг. При этом Продавец может ограничивать условия действия скидок, ограничивать их предельный размер. Подробные условия предоставления коммерческих скидок представлены на Сайте. Скидка может предоставляться на последующий Заказ также в целях компенсации возможного увеличения стоимости Товара и/или Услуги при оформлении нового Заказа в случае замены Товара или обмена на аналогичный, отмены Услуги и в других случаях, когда, согласно настоящей Оферте, требуется пересчитать стоимость Товара и/или Услуг.
7.16.	При проведении маркетинговых мероприятий, предполагающих вложение каких-либо объектов в Заказы Покупателя, доставка указанных вложений осуществляется вместе с Заказом Покупателя.
7.17.	В стоимость Заказа не включены таможенные пошлины, которые оплачиваются Покупателем самостоятельно. Условия оплаты и размер таможенных пошлин определяются таможенным законодательством той страны, куда осуществляется доставка Заказа.

8.	Возврат и обмен товара

8.1.	Возврат и обмен товара производятся в соответствии с Постановлением Правительства РФ от 27.09.2007 г. № 612 «Об утверждении Правил продажи товаров дистанционным способом», Постановлением Правительства РФ от 31 декабря 2020 г. № 2463 «Об утверждении Правил продажи товаров по договору розничной купли-продажи, перечня товаров длительного пользования, на которые не распространяется требование потребителя о безвозмездном предоставлении ему товара, обладающего этими же основными потребительскими свойствами, на период ремонта или замены такого товара, и перечня непродовольственных товаров надлежащего качества, не подлежащих обмену, а также о внесении изменений в некоторые акты Правительства Российской Федерации», а также Законом РФ от 07.02.1992 г. № 2300-1 «О защите прав потребителей».
8.2.	Покупатель обязан приложить к претензии фотографии, подтверждающие наличие недостатков Товара и/или Услуг, акт, подтверждающий приемку Товара и/или оказание Услуг, и документ об оплате Товара и/или Услуг. 
8.3.	Дополнительные условия возврата и обмена Товаров и заявления претензий в отношении качества Услуг определяются в разделе «Условия возврата» Сайта.

9.	Ответственность

9.1.	Покупатель несет полную ответственность за предоставление неверных, неправильных или неточных сведений, повлекшее за собой невозможность надлежащего исполнения Продавцом своих обязательств перед Покупателем.
9.2.	Продавец не несет ответственности за точность и правильность информации, предоставляемой Покупателем при Верификации.
9.3.	Продавец не несет ответственности за прямые или косвенные убытки, возникшие в результате ненадлежащего использования Товаров, приобретенных на Сайте.
9.4.	Продавец не несет ответственность за ущерб, причиненный Покупателю, третьим лицам в результате использования или невозможности использования Сайта или отдельных его функций, в том числе из-за возможных ошибок или сбоев в работе Сайта, за исключением случаев, прямо предусмотренных законодательством.
9.5.	Продавец вправе переуступать либо каким-либо иным способом передавать свои права и обязанности, вытекающие из его отношений с Покупателем, третьим лицам.
9.6.	Продавец не несет ответственности за убытки, которые Покупатель может понести в результате того, что его номер телефона перешел в собственность другого лица, в том числе в результате длительного (от 3х месяцев) неиспользования такого номера телефона Покупателем.
9.7.	Стороны Договора освобождаются от ответственности за полное или частичное неисполнение своих обязательств, если такое неисполнение явилось следствием действия обстоятельств непреодолимой силы, которые Стороны не могли предвидеть и предотвратить разумными мерами.
9.8.	Продавец вправе отказать в передаче неоплаченного Товара и/или оказания неоплаченных Услуг без уплаты каких-либо штрафных санкций.

10.	Дополнительные условия

10.1.	Отношения между Покупателем и Продавцом регулируются законодательством Российской Федерации.
10.2.	Покупатель подтверждает, что условия настоящего Договора ему понятны, и он принимает их и в полном объёме без каких-либо оговорок.
10.3.	В случае возникновения споров и разногласий Стороны будут решать их путем переговоров, при не достижении соглашения, споры подлежат рассмотрению в суде соответствии с законодательством Российской Федерации.
10.4.	Во всем ином, что не предусмотрено Договором, Стороны руководствуются законодательством Российской Федерации.
10.5.	В случае признания судом недействительности какого-либо из положений Договора, остальные положения Договора продолжают действовать.
10.6.	Настоящая Оферта вступает в силу с даты оформления Заказа и действует до полного выполнения Сторонами всех обязательств по нему.
`