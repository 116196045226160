export const PRIVACY_POLICY_TEXT = `
Политика в отношении обработки персональных данных

ПРЕАМБУЛА

Настоящая политика в отношении обработки персональных данных (далее по тексту– Политика) определяет основные принципы, условия, цели, способы обработки, реализуемые меры защиты персональных данных и иной информации, права пользователей сети Интернет (далее по тексту – Пользователь) в процессе использования сайта ${window.location.protocol}//${window.location.host}${window.initialConstants.ROOT_PATH ? `/${window.initialConstants.ROOT_PATH}` : ''} (далее по тексту – Сайт), доступ к которому предоставляется ${window.initialConstants?.SHOP_NAME}, далее по тексту – Общество, Администратор, Оператор, для обеспечения соблюдения и защиты прав и свобод каждого человека и, в особенности, права на неприкосновенность частной жизни, личную и семейную тайну, защиту чести, достоинства и доброго имени.

Настоящая Политика регулирует условия конфиденциальности и обработки персональных данных между Пользователем и Обществом при использовании Сайта, а также заключении и исполнении договоров между ними.

Политика действует в отношении всей информации, включая персональные данные в понимании действующего законодательства Российской Федерации, которую Администратор может получить от Пользователя в процессе использования им Сайта.

Действие настоящей Политики распространяется на всех Пользователей, которые предоставляют свои персональные данные Администратору при любом доступе и (или) использовании Сайта, и в отношении которых Администратор осуществляет обработку персональных данных. Требования Политики также учитываются и предъявляются в отношении иных лиц при необходимости их участия в процессе обработки персональных данных Администратором.

Перед использованием Сайта Администратора Пользователь обязан ознакомиться с содержанием данного документа. При каждом доступе и/или фактическом использовании Сайта или его отдельных функций Пользователь соглашается с настоящей Политикой в полном объеме, без оговорок и исключений. Принятие условий Политики Пользователем является явно выраженным, предметным, определенным и неабстрактным согласием Пользователя на обработку персональных данных.

1.	ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ

1.1.	В целях настоящей Политики, если из контекста не следует иное, нижеприведенные термины имеют следующие значения и являются её составной неотъемлемой частью:
1.1.1.	Персональные данные — любая информация, относящаяся к прямо или косвенно определенному или определяемому физическому лицу.
1.1.2.	Обработка Персональных данных — любое действие (операция) или совокупность действий (операций) с Персональными данными, совершаемых с использованием средств автоматизации или без их использования. Обработка Персональных данных включает в себя в том числе сбор запись, систематизацию, накопление, хранение, уточнение (обновление, изменение, извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение.
1.1.3.	Сайт — веб-сайт, расположенный по адресу ${window.location.protocol}//${window.location.host}${window.initialConstants.ROOT_PATH ? `/${window.initialConstants.ROOT_PATH}` : ''}.
1.1.4.	Пользователь — субъект персональных данных, лицо, осуществляющее использование Сайта.
1.1.5.	Общество, Администратор, Оператор — ${window.initialConstants?.SHOP_NAME}, являющееся оператором Персональных данных. 
1.2.	В Политике могут быть использованы иные термины, не определенные в п. 1.1 настоящего документа. В этом случае толкование таких терминов производится в соответствии с текстом Политики. 
1.3.	Термины, употребляемые в настоящей Политике в единственном числе (в зависимости от контекста), имеют такое же значение во множественном числе и наоборот. Заголовки в настоящей Политике выделены только для удобства и не влияют на её толкование.

2.	ОБЩИЕ ПОЛОЖЕНИЯ

2.1.	Настоящая Политика разработана в соответствии с Конституцией Российской Федерации, Гражданским кодексом Российской Федерации, Федеральным законом от 27.07.2006 г. № 149-ФЗ «Об информации, информационных технологиях и о защите информации», Федеральным законом от 27.07.2006 г. № 152-ФЗ «О персональных данных», иными федеральными законами.
2.2.	Цель разработки Политики — определение порядка обработки и защиты Персональных данных Пользователей, обеспечение защиты прав и свобод человека и гражданина при обработке его Персональных данных, в том числе защиты прав на неприкосновенность частной жизни, личную и семейную тайну.
2.3.	Настоящая Политика вступает в силу с момента её утверждения и действует бессрочно, до замены её новой Политикой. 
2.4.	Политика может быть изменена и/или дополнена в одностороннем порядке Администратором Сайта без какого-либо специального уведомления (согласия) Пользователей. Действующая редакция Политики размещена в сети Интернет по адресу: ${window.location.href}.
2.5.	Все изменения и дополнения настоящей Политики вступают в силу на следующий день после размещения новой редакции Политики по адресу: ${window.location.href}.
2.6.	Продолжение использования Сайта после внесения изменений и/или дополнений в настоящую Политику будет означать принятие и согласие Пользователя с такими изменениями и/или дополнениями.
2.7.	Если Пользователь не согласен с условиями и положениями Политики и правилами пользования Сайта, он должен незамедлительно прекратить использование Сайта. В противном случае, использование Сайта будет означать безусловное и безоговорочное согласие Пользователя с условиями настоящей Политики.
2.8.	Предоставляя Пользователям возможность использования Сайта, Администратор, действуя разумно и добросовестно, предполагает, что Пользователь: 
	перед началом использования Сайта внимательно ознакомился с условиями настоящей Политики;
	обладает всеми необходимыми правами, позволяющими регистрироваться и авторизоваться на Сайте и использовать его функционал;
	приступив к пользованию Сайта, выразил свое согласие с условиями настоящей Политики и принял на себя указанные в ней права и обязанности;
	осознаёт, что информация, переданная им другим Пользователем, не может быть удалена им самостоятельно;
	понимает и осознает, что в процессе использования Сайта информация, размещенная Пользователем о себе, может быть доступна другим Пользователям, при этом Оператор не несет ответственности за действия третьих лиц;
	регулярно проверяет условия настоящей Политики на предмет её изменения и/или дополнения.
2.9.	При каждом доступе и/или фактическом использовании Сайта, Пользователь: 
	соглашается с настоящей Политикой в полном объеме, без оговорок и исключений;
	подтверждает, что настоящая Политика будет применяться к использованию им Сайта с момента доступа и/или фактического использования;
	принимает на себя права и обязанности, регламентированные настоящей Политикой, которая представляет собой соглашение между Пользователем и Администратором; 
	подтверждает и соглашается, что данная Политика должна применяться ко всем персональным данным, передаваемым им Администратору.
2.10.	Настоящая Политика применяется только к Администратору и Сайту Администратора. Администратор не контролирует и не несет ответственности за сайты третьих лиц, на которые посетитель либо пользователь может перейти по ссылкам, доступным на Сайте.
2.11.	Обработка Персональных данных может осуществляться Администратором совместно с иным оператором по обработке Персональных данных.
2.12.	Администратор является Оператором по обработке Персональных данных, за исключением случаев, когда функции по обработке Персональных данных переданы другому лицу на основании заключенного с таким лицом договора. 

3.	ЦЕЛЬ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ

3.1.	Персональные данные обрабатываются Оператором исключительно в тех целях, с которыми они были предоставлены, в том числе для:
	заключения, исполнения, изменения и расторжения договоров с Оператором;
	оказания консультационных, информационных и иных услуг;
	коммуникациис другими Пользователями и Администратором;
	полученияконсультационной поддержки;
	предупреждения и пресечения нарушений договоров, заключенных с Администратором, и иных незаконных или несанкционированных действий Пользователей или третьих лиц;
	организацииучастия Пользователей в мероприятиях и опросах, организованных Администратором;
	соблюдения требований законодательства Российской Федерации;
	анализа данных с целью улучшения функционала и повышения качества Сайта;
	организации хранения и уничтожения источников информации, в том числе содержащих Персональные данные Пользователей;
	обеспечения функционирования и безопасности Сайта;
	улучшения функционирования Сайта;
	разработки новых инструментов Сайта;
	обеспечения взаимодействия Пользователя и Администратора в рамках маркетинговых предложений, которые могут заинтересовать Пользователя (за исключением случаев, когда Пользователь отказался от маркетинговых рассылок, или Администратору разрешается их распространять в силу применимого законодательства);
	проверки личности Пользователя в целях защиты от мошенничества и подтверждения правомерности использования Сайта;
	достижения иных целей с согласия Пользователей.

4.	ПРАВА И ОБЯЗАННОСТИ СТОРОН

4.1.	Оператор вправе:
4.1.1.	Самостоятельно определять состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей, предусмотренных законодательством Российской Федерации в отношении Персональных данных;
4.1.2.	Поручать обработку Персональных данных другому лицу с согласия Пользователя, если иное не предусмотрено федеральным законом, на основании заключаемого с этим лицом договора;
4.1.3.	В случае отзыва Пользователем согласия на обработку Персональных данных Оператор вправе продолжить обработку Персональных данных без согласия Пользователя при наличии на то законных оснований.
4.2.	Оператор обязан:
4.2.1.	Организовывать обработку Персональных данных в соответствии с требованиями законодательства Российской Федерации;
4.2.2.	Отвечать на обращения и запросы Пользователей в соответствии с требованиями законодательства Российской Федерации;
4.2.3.	Сообщать в уполномоченный орган по защите прав Пользователей (Федеральную службу по надзору в сфере связи, информационных технологий и массовых коммуникаций (Роскомнадзор)) по запросу этого органа необходимую информацию в соответствии с действующим законодательством Российской Федерации.
4.3.	Пользователь вправе:
4.3.1.	Получать информацию, касающуюся обработки его Персональных данных;
4.3.2.	Требовать от Оператора уточнения его Персональных данных, их блокирования или уничтожения в случае, если Персональные данные являются неполными, устаревшими, неточными. В случае выявления неточностей в персональных данных, Пользователь может актуализировать их самостоятельно, путем направления Оператору уведомления на адрес электронной почты Оператора с пометкой «Актуализация персональных данных».
4.3.3.	Отказаться от обработки Оператором Персональных данных Пользователя, направив соответствующий запрос на адрес с пометкой «Отзыв согласия на обработку персональных данных». В таком случае Пользователь обязан прекратить использование Сайта;
4.3.4.	Принимать предусмотренные законом меры по защите своих прав.
4.4.	Пользователь обязан: 
4.4.1.	Предоставить достоверную, полную и актуальную информацию по всем вопросам, запрашиваемым на Сайте;
4.4.2.	Строго выполнять все требования законодательства Российской Федерации;
4.4.3.	Выполнять требования и соблюдать условия настоящей Политики и иных документов, размещенных на Сайте;
4.4.4.	Не использовать информацию, расположенную на Сайте, с целью распространения; 
4.4.5.	Не использовать нецензурную лексику, эротические, оскорбительные изображения и тексты, информацию и высказывания, которые содержат угрозы, провоцируют жестокость, ненависть, неуважительное отношение или могут привести к незаконным действиям, а также иную информацию и иные высказывания, которые не соответствуют общепринятым нормам нравственности и морали;
4.4.6.	Не совершать действий, которые могут ограничить доступ к Сайту.
4.5.	Невключение в настоящую Политику каких-либо из прав и (или) обязанностей Оператора и/или Пользователя, установленных действующим законодательством и иными нормативными правовыми актами, не может рассматриваться как отказ от реализации этих прав или исполнения этих обязанностей.
4.6.	Контроль за исполнением требований настоящей Политики осуществляется уполномоченным лицом, ответственным за организацию обработки персональных данных у Оператора.
4.7.	Ответственность за нарушение требований законодательства Российской Федерации и нормативных актов Общества в сфере обработки и защиты персональных данных определяется в соответствии с законодательством Российской Федерации

5.	ПЕРЕЧЕНЬ ОБРАБАТЫВАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ

5.1.	Оператор вправе собирать следующие категории Персональных данных Пользователя:
	информация, предоставленная Пользователем при регистрации и/или авторизации на Сайте, в частности, имя, фамилия, отчество, номер телефона, год, месяц и дата рождения, номер телефона, адрес электронной почты, адрес, по которому будет осуществляться доставка товаров / оказание услуг;
	информация, которая указывается Пользователем с целью оформления заказа и (или) заключения договора с Администратором, в частности, но не ограничиваясь, фамилия, имя, отчество, адрес электронной почты, паспортное данные, год, месяц и дата рождения, почтовый адрес, номер мобильного телефона, банковские реквизиты, адрес, по которому будет осуществляться доставка товаров / оказание услуг;
	электронные данные (HTTP-заголовки, IP-адрес, файлы cookies, веб-маяки/пиксельные теги, данные об идентификаторе браузера, информация об аппаратном и программном обеспечении);
	информация, размещенная Пользователем при направлении сообщения в службу поддержки Администратора; 
	дата и время осуществления доступа к Сайту;
	информация об активности Пользователя во время использования Сайта, о поведенческих действиях Пользователя;
	копии документов, удостоверяющих личность, а также иных документов, предоставляемых Пользователем, и содержащих Персональные данные;
	иная информация Пользователя, необходимая для обработки в соответствии с условиями, регулирующими использование Сайта.
5.2.	Оператор также вправе хранить: 
	договоры, заключаемые между Пользователями и Администратором;
	заявки на заключение договора, направляемые Пользователями;
	записи телефонных переговоров и электронную переписку.
5.3.	Оператор также обрабатывает техническую и иную информацию, которая автоматически передается устройством, с помощью которого Пользователь использует Сайт, сохраненную в файлах куки (cookies), которые были отправлены на устройство, информацию о браузере и его настройках, дате и времени доступа к Сайту, адресах запрашиваемых страниц, действиях Пользователя на Сайте, технических характеристиках устройства Пользователя, IP-адресе и иную подобную информацию. Оператор обрабатывает вышеназванную информацию с целью улучшения технических возможностей Сайта, изучения анализа пользовательской активности, оптимизации функционирования Сайта.
 
6.	ПОРЯДОК ПОЛУЧЕНИЯ (СБОРА) ПЕРСОНАЛЬНЫХ ДАННЫХ

6.1.	Оператор получает все Персональные данные Пользователя с его письменного согласия, кроме случаев, предусмотренных законодательством Российской Федерации.
6.2.	Согласие на обработку Персональных данных предоставляется Пользователем в электронной или простой письменной форме и является конкретным, предметным, информированным, сознательным и однозначным. 
6.3.	Согласие Пользователя на обработку Персональных данных действует в течение пяти лет с даты его принятия. По истечении указанного срока действие согласия считается продленным на каждые следующие пять лет при отсутствии сведений о его отзыве Пользователем.
6.4.	Обработка Персональных данных Пользователя без его согласия осуществляется в случаях:
	по требованию полномочных государственных органов в случаях, предусмотренных законодательством Российской Федерации;
	если Персональные данные являются общедоступными;
	если их обработка осуществляется в целях заключения и исполнения договора, одной из сторон которого является сам Пользователь;
	если обработка Персональных данных осуществляется для статистических целей при условии обязательного обезличивания Персональных данных;
	в иных случаях, предусмотренных законом. 
6.5.	Оператор не получает и не обрабатывает Персональные данные Пользователя о его расовой, национальной принадлежности, политических взглядах, религиозных или философских убеждениях, состоянии здоровья, интимной жизни.
6.6.	Пользователь гарантирует, что Персональные данные, которые он предоставляет Оператору, являются достоверными. Оператор не несёт ответственности за недостоверность предоставляемых Пользователями Персональных данных и иной информации.

7.	ОСНОВАНИЯ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ

7.1.	Оператор обрабатывает Персональные данные и другую информацию, предоставляемые Пользователем при:
	оформлении и заключении договоров с Администратором, направлении заявок на заключение договоров с Администратором;
	каждом доступе и/или фактическом использовании Сайта;
	подписке на информационные рассылки Администратора;
	прохождении опросов и участии в исследованиях, организованных Администратором;
	обращении в службу поддержки Администратора;
	реализации прав и обязанностей, вытекающих из законодательства Российской Федерации.
7.2.	Оператор обрабатывает Персональные данные и другую информацию, предоставляемые Пользователем, если соблюдено хотя бы одно из следующих условий: 
	обработка осуществляется с согласия Пользователя;
	обработка необходима для заключения, исполнения, изменения или прекращения договоров, заключенных между Администратором и Пользователем;
	обработка осуществляется в связи с участием в судопроизводстве или для исполнения акта, подлежащего исполнению в соответствии с законодательством об исполнительном производстве;
	обработка необходима для осуществления прав и законных интересов Администратора, других Пользователей или третьих лиц;
	обработка необходима для выполнения функций, полномочий и обязанностей, возложенных на Администратора законодательством Российской Федерации.
7.3.	Обработка Персональных данных осуществляется на основе принципов:
	законности целей и способов обработки Персональных данных;
	ограничения обработки Персональных данных достижением конкретных, заранее определенных и законных целей;
	недопущения обработки Персональных данных, несовместимой с целями сбора Персональных данных;
	соответствия целей обработки Персональных данных целям, заранее определенным и заявленным при сборе Персональных данных, а также полномочиям Оператора;
	соответствия объема и характера обрабатываемых Персональных данных, способов обработки Персональных данных целям обработки Персональных данных.

8.	ЗАЩИТА ПЕРСОНАЛЬНЫХ ДАННЫХ ПОЛЬЗОВАТЕЛЕЙ

8.1.	Защита Персональных данных Пользователя осуществляется за счёт Администратора в порядке, установленном законодательством Российской Федерации. 
8.2.	Оператор принимает все возможные и необходимые правовые, технические и организационные меры в целях защиты Персональных данных Пользователя от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования, копирования, распространения, а также от иных неправомерных действий. К таким, в частности, мерам относятся:
	получение согласий Пользователей на обработку их Персональных данных, за исключением случаев, предусмотренных законодательством Российской Федерации;
	анализ состояния защищенности Сайта;
	обнаружение и предотвращение вторжений в процесс функционирования Сайта;
	мониторинг действий с Персональными данными;
	иные меры, предусмотренные законодательством в области Персональных данных.
8.3.	Доступ к Персональным данным Пользователя может также предоставляться сотрудникам Администратора, которым Персональные данные необходимы в связи с исполнением ими трудовых обязанностей. 

9.	ПЕРЕДАЧА ПЕРСОНАЛЬНЫХ ДАННЫХ ТРЕТЬИМ ЛИЦАМ.

9.1.	В порядке, предусмотренном действующим законодательством, Персональные данные Пользователя могут передаваться третьим лицам, для достижения целей, указанных в разделе 3 настоящей Политики. К таким третьим лицам могут относиться:
	любой орган государственной власти Российской Федерации, орган государственной власти субъектов Российской Федерации, орган местного самоуправления и другие официальные органы, в отношении которых Администратор обязан предоставлять информацию в соответствии с применимым законодательством Российской Федерации по соответствующему запросу;
	третьи лица, в случае если Пользователь выразил согласие на передачу Персональных данных;
	в иных случаях, предусмотренных законодательством. 

10.	ХРАНЕНИЕ И УНИЧТОЖЕНИЕ ИНФОРМАЦИИ

10.1.	Под хранением Персональных данных понимается существование записей в информационных системах и на материальных носителях.
10.2.	Хранение Персональных данных Пользователя может осуществляться до тех пор, пока это необходимо для выполнения целей, с которыми они были собраны, если иное не предусмотрено федеральными законами Российской Федерации.
10.3.	Оператор закрывает доступ и запрещает использование Персональных данных, которые более не требуются для использования Сайта, поддержки Пользователей, повышения качества обслуживания и других операционных целей. Такие данные используются исключительно для соблюдения настоящих требований, обеспечения безопасности, выявления и предотвращения случаев мошенничества.
10.4.	Оператор вправе сохранять определенную информацию, если это необходимо для соблюдения его законных деловых интересов, таких как предотвращение мошенничества и обеспечение безопасности и защиты Пользователей. 
10.5.	Уничтожение Персональных данных Пользователя подразумевает прекращение какого-либо доступа к Персональным данным.
10.6.	 При уничтожении Персональных данных Пользователя работники Администратора не могут получить доступ к его Персональным данным субъекта в информационных системах Сайта.
10.7.	 При уничтожении Персональных данных Персональные данные в информационных системах обезличиваются. Уничтоженные Персональные данные восстановлению не подлежат.
10.8.	Операция уничтожения Персональных данных необратима. 

11.	ОБЩЕДОСТУПНЫЕ ДАННЫЕ

11.1.	Оператор запрещает собирать, извлекать, записывать, систематизировать, хранить, передавать, распространять, копировать, воспроизводить или иным образом использовать для любых иных коммерческих или некоммерческих целей данные, которые Пользователь сделал общедоступными.
11.2.	Оператор не несёт ответственности за действия третьих лиц в отношении любой информации, размещенной на Сайте в открытом доступе, в том числе за её достоверность. 
11.3.	Пользователь самостоятельно несет все риски, связанные с таким размещением общедоступной информации.

12.	СРОКИ ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ

12.1.	Оператор прекращает обработку Персональных данных:
	по достижении целей обработки либо в случае утраты необходимости в достижении этих целей;
	если для обработки Персональных данных не будет иных правовых оснований, предусмотренных законодательством Российской Федерации;
	по истечении срока действия согласия Пользователя на обработку Персональных данных или в случае отзыва такого согласия, если для обработки не будет иных правовых оснований, предусмотренных законодательством Российской Федерации;
	в случае выявления неправомерной обработки Персональных данных, если обеспечить правомерность обработки невозможно;
	в случае ликвидации Администратора.
12.2.	По истечении срока хранения Персональные данные могут быть обезличены в информационных системах и уничтожены на бумажном носителе в порядке, установленном в действующем законодательстве Российской Федерации.

13.	ОБРАЩЕНИЯ ПОЛЬЗОВАТЕЛЕЙ

13.1.	Пользователи вправе направлять Администратору свои запросы, в том числе запросы относительно использования их Персональных данных, путем направления сообщения: 
	в простой письменной форме на адрес;
	в электронной форме на электронную почту Администратора.
13.2.	Администратор обязуется рассмотреть и направить ответ на поступивший запрос Пользователя в сроки, установленные законом.

`